@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.game-square {
    border: solid gray 1px;
    background-color: lightgray;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    text-align: center;

    --mine-probability: 0;
    --mine-probability-color: hsl(0, calc(var(--mine-probability) * 100%), 80%);
    /*--mine-probability-color: hsl(0, 50%, 50%);*/
}

.game-square--flagged,
.game-square--pristine {
    --border-width: calc(var(--cell-size) * .1);
    border: outset white var(--border-width);
    cursor: pointer;
}

.game-square--pristine:hover {
    background-color: grey;
}

.game-square--neighbours-known {
    --size: calc(var(--cell-size) * .9);
    font-family: 'Roboto Slab', serif;
    font-size: var(--size);
    line-height: var(--size);
}

.game-square--flagged {
    background-image: url(/static/media/flag-1.42fcd612.svg);
}

.game-square--mined {
    background-image: url(/static/media/mine-6.8c62354d.svg);
}

.game-square--flagged.game-square--mined {
    background-image: url(/static/media/flagbomb.277869b1.svg);
}

.game-square--killer-mine {
    background-image: url(/static/media/explosion.111d26b0.svg);
    background-color: black;
    /*background-size: 90%;*/
}

.game-square--on-frontier-and-unknown {
    background-color: lightgoldenrodyellow;
}

.game-square--known-non-mine {
    background-color: lightgreen;
}

.game-square--known-mine {
    background-color: red;
}

.game-square--has-some-chance-of-being-a-mine {
    background-color: red;
}

.game-square__probability-overlay {
    height: 100%;
    width: 100%;
}

.game-square--has-known-probability.game-square--on-frontier-and-unknown {
    /* Unset the yellow color */
    background-color: lightgray;
}

.game-square--has-known-probability .game-square__probability-overlay {
    background-color: red;
    /*background-color: var(--mine-probability-color);*/
    opacity: var(--mine-probability);
}

.board {
    border: solid black var(--game-border-size);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.board--game-over {

    /*margin: 20px;*/

}

.board__grid {

    display: grid;
    grid-template-rows: repeat(var(--rows), var(--cell-size));
    grid-template-columns: repeat(var(--cols), var(--cell-size));

}

.game-state-indicator {
    display: flex;
    flex-direction: row;
    /*align-content: space-around;*/
    /*align-self: center;*/
    justify-content: space-evenly;
}

.game-over-indicator {
    --size: 50px;
    height: var(--size);
    width: var(--size);
    border: solid black 2px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 5px;
}

.game-over-indicator--success {
    /*background-color: green;*/
    background-image: url(/static/media/win-2.bc7c9040.svg);
}

.game-over-indicator--failure {
    /*background-color: red;*/
    background-image: url(/static/media/fail-5.2b477234.svg);
}

.game-over-indicator--game-over {
    transition: background-color;
    border-color: lawngreen;
}

.game-over-indicator--game-in-progress {
    background-image: url(/static/media/sunshine.4db827e5.svg);
}

.game-status-text {
    /*padding: 10px;*/
}

.made-by-text {
    margin-left: 20px;
}

.minesweeper-game {
    /* Injected variables */
    --rows: 'overwritten in js: number of rows in the puzzle';
    --cols: 'overwritten in js: number of cols in the puzzle';
    --cell-size: 40px;
    --game-border-size: 2px;

}

.gameplay-elements {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-left: 20px;
    width: calc(var(--cols) * var(--cell-size) + 2 * var(--game-border-size));
}

.options-groups {
    display: flex;
    flex-direction: row;

}

.options-group {
    padding: 20px;
}

.options-dropdown {

}

.textbox {

}

.textbox--small {
    width: 40px;
}

.game-button {
    margin-top: 5px;
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
}

.restart-button {
}

.step-button {
    font-size: unset;

}
/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

* {
    box-sizing: border-box;
}
