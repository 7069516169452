.made-by-text {
    margin-left: 20px;
}

.minesweeper-game {
    /* Injected variables */
    --rows: 'overwritten in js: number of rows in the puzzle';
    --cols: 'overwritten in js: number of cols in the puzzle';
    --cell-size: 40px;
    --game-border-size: 2px;

}

.gameplay-elements {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin-left: 20px;
    width: calc(var(--cols) * var(--cell-size) + 2 * var(--game-border-size));
}

.options-groups {
    display: flex;
    flex-direction: row;

}

.options-group {
    padding: 20px;
}

.options-dropdown {

}

.textbox {

}

.textbox--small {
    width: 40px;
}

.game-button {
    margin-top: 5px;
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
}

.restart-button {
}

.step-button {
    font-size: unset;

}