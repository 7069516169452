.game-state-indicator {
    display: flex;
    flex-direction: row;
    /*align-content: space-around;*/
    /*align-self: center;*/
    justify-content: space-evenly;
}

.game-over-indicator {
    --size: 50px;
    height: var(--size);
    width: var(--size);
    border: solid black 2px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 5px;
}

.game-over-indicator--success {
    /*background-color: green;*/
    background-image: url("../img/win-2.svg");
}

.game-over-indicator--failure {
    /*background-color: red;*/
    background-image: url("../img/fail-5.svg");
}

.game-over-indicator--game-over {
    transition: background-color;
    border-color: lawngreen;
}

.game-over-indicator--game-in-progress {
    background-image: url("../img/sunshine.svg");
}

.game-status-text {
    /*padding: 10px;*/
}
